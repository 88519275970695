//part of library

.verticallistmenu {
    ul {
        @include reset-list;
        li {
            display:block;
        }
    }

    a {
        display:block;
    }
}

//default styling. this block can be removed.
.verticallistmenu {
    ul {
        a {
            text-align: center;
        }
        .level1 + .level1 {
            margin-top:1em;
        }

        .level2 {
            font-size:0.8em;
            opacity:0.7;
        }
        .level3 {
            font-size:0.7em;
            opacity: 0.5;
        }
    }
}