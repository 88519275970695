.form-contactform {
    input, textarea, select {
        border:2px solid $black;
        border-radius:0;
        font:inherit;
        padding:0.25em 0.3em 0.15em 0.3em;

        width: 100%;
        box-sizing: border-box;

        outline:none;

        &::placeholder {
            color: transparent;
        }
    }
    textarea {
        height:7em;
    }

    .vf-element-layout + .vf-element-layout {
        margin-top:0.75em;
    }

    .vf-element-layout-outer-wrapper {
        position: relative;
            
        label {
            position: absolute;
            left:0.5em;
            top:0.35em;
            
            pointer-events: none;

            transition: all .2s;
            transform-origin: left top;
        }

        &:focus-within,
        &:not(:has(input:placeholder-shown)):not(:has(textarea:placeholder-shown))
        {
            label {
                left:0.35em;
                top:-0.3em;
                transform: scale(0.5);
                background-color: #fff;
            }
        }        
    }
}

.form-terminform {
    input, textarea, select {
        border:none;
        border-radius:0;
        font:inherit;
        padding:0.1em 0;

        width: 100%;
        box-sizing: border-box;

        outline:none;

        &::placeholder {
            color: $black;
            opacity: 1;
        }
    }
    .vf-element-layout:has(input) {
        border-bottom:2px solid $black;
    }
    .vf-element-layout-outer-wrapper:has(:is(input[type=date], input[type=time])) {
        display:flex;
        gap:0.5em;
    }
    .vf-element-layout:has(button) {
        margin-top:1em;
    }
}

//typo
.form-contactform, .form-terminform {
    .vf-element-error {
        color:red;
        font-size:0.5em;
    }
}
