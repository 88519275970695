.bl-title {
    h1, h2 {
        margin-bottom: 0;
    }
    &.layoutcentered {
        h1, h2 {
            text-align: center;
        }
    }
}

