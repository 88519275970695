.bl-text2col {
    @include breakpoint(large) {
        .ctrcols {
            @include grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }
    @include breakpoint(medium down) {
        .col1, .col2 {
            margin-top:2em;
        }
    }
}