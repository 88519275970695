.bl-vorgehen {
    @include breakpoint(large) {
        @include grid12;

        & > .linner {
            grid-column: 3 / span 8;
        }
    }

    .ctrscrollarea {
        position: relative;
    }
    .scrollindicator-track {
        position: absolute;
        left:50%;
        width:1px;
        top:0;
        height:100%;

        background-color: $kkbton02;
    }
    .scrollindicator-slider {
        position: sticky;
        top:50vh;
        height:200px;

        margin-left:-4px;
        margin-right:-4px;
        
        background-color: $kkhauptrot;
        border-radius: 99px;
    } 

    .item {
        transition: opacity .2s;
    }
    .item:not(.highlighted) {
        opacity:0.3;
    }
    .item.highlighted {
    }

    .item {
        @include rem-calc-sl(padding-top, 100);
        @include rem-calc-sl(padding-bottom, 100);
        &:first-child {
            padding-top:0;
        }
        &:last-child {
            padding-bottom:0;
        }
    }

    @include breakpoint(large) {
        .item {
            @include grid;
            grid-template-columns: repeat(8, 1fr);
        }
        .item:nth-child(odd) .ctrbox {
            grid-column:1 / span 3;
        }
        .item:nth-child(even) .ctrbox {
            grid-column:6 / span 3;
        }
    }
    @include breakpoint(medium down) {
        .scrollindicator-track {
            left:0;
        }
        .items {
            margin-left:1.5em;
        }
    }
}

//typo
.bl-vorgehen {
    .stepnumber {
        @include typo-h1;
        margin-bottom: 0.75em;
    }
}