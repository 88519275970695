#totopbutton {
    position:fixed;
    left:50%;
    transform:translateX(-50%);
    bottom:rem-calc(24);

    z-index:10;

    cursor: pointer;

    img {
        height:rem-calc(50);
        display: block;
        transition: transform .2s;
    }
}

