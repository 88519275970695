.bl-contactform {
    .ctrcols {
        @include grid;
        grid-template-columns: 2fr 3fr;
        @include breakpoint(small down) {
            grid-template-columns: 1fr;
        }
    }
}

//typo
.bl-contactform {
    .ctrtextl {
        a {
            @include text-decoration-underline;
        }
        a[href^="tel:"] {
            text-decoration: none;
            &:hover {
                @include text-decoration-underline;
            }
        }
    }
}

table.openingtimes {
    border:none;
    width:auto !important;
    td {
        width:auto !important;
        vertical-align: top;
        padding-right: 1em;
        padding-bottom:1em;
    }
}
