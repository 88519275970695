body:not(.reveal-initialized) .reveal {
    visibility: hidden;
}

.reveal-hidden {
    visibility: hidden;
}

/*
.reveal-fadeInLeft {
    animation-duration:1s;
    animation-fill-mode:both;
    animation-name: fadeInLeft;
}
@keyframes fadeInLeft {
    0% {
        opacity:0;
        transform:translateX(-120px);
    }
    100% {
    }
}

.reveal-fadeInRight {
    animation-duration:1s;
    animation-fill-mode:both;
    animation-name: fadeInRight;
}
@keyframes fadeInRight {
    0% {
        opacity:0;
        transform:translateX(120px);
    }
    100% {
    }
}
*/