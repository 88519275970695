.bl-teaserinfoabend {
    @include rem-calc-sl(--boxpadding, 60, math.div(30, 60));

    .ctrtextmain {
        padding:var(--boxpadding);
        background-color: white;
    }

    @include breakpoint(xlarge) {
        @include grid12;

        .ctrtextmain {
            grid-column: 3 / span 6;
            grid-row: 1;
            padding-right: 25%; //compensate red box overlap
        }

        .ctrtextside {
            grid-column: 8 / span 3;
            grid-row: 1;

            margin-left: calc(-1 * var(--layoutgridgap));

            align-self: center;
        }
    }
    @include breakpoint(large down) {
        .ctrtextmain {
            @include rem-calc-sl(padding-bottom, 240, 0.5);
        }
        .ctrtextside {
            @include rem-calc-sl(margin-top, -120, 0.5);
            margin-left:var(--boxpadding);
            margin-right:var(--boxpadding);
        }
    }

    .ctrtextside {
        background-color: $kkrot;
        padding:var(--boxpadding);

        ul {
            @include reset-list;

            li + li {
                margin-top:1em;
            }
        }
    }

    .ctrbuttons {
        @include rem-calc-sl(margin-top, 40, math.div(30, 40));
    }
}

//typo
.bl-teaserinfoabend {
    .ctrtextmain {
        @include font-size-pt(26, 35);
        font-weight: 300;
        line-height: math.div(45, 35);
    }

    .ctrtextside {
        color:$white;
        @include font-size-pt(17, 22);
        font-weight: 400;
        line-height: math.div(24, 17);

        h3 {
            margin-bottom:1em;
        }
    }
}