#offcanvasmenu {
    background-color: #000;
    color:#fff;

    position: fixed;
    right: 0;
    top: 0;
    width: auto;
    height:100%;

    overflow: auto;
    overscroll-behavior: contain;

    z-index: 50;

    box-sizing: border-box;
    padding-top:rem-calc(120);
    padding-bottom:rem-calc(120);
    @include rem-calc-sl(padding-left, 90, 0.5);
    @include rem-calc-sl(padding-right, 90, 0.5);

    .ctrlangchooser {
        text-align: center;
        margin-top:3em;
    }
}

#offcanvasmenu {
    transition:transform .4s;
}
#offcanvasmenu:not(.visible) {
    pointer-events: none;
    transform:translateX(100%);
}

#hamburger-offcanvasmenu {
    position:fixed;
    @include rem-calc-sl(top, 32);
    right:var(--lrmargin);
    z-index: 200;
    //background-color: white;
    --hbnormal:black;
    --hbhover:black;
    body.headercolorwhite & {
        --hbnormal:white;
        --hbhover:white;
    }
    body.headercolorwhite:has(header.opaque) & {
        --hbnormal:black;
        --hbhover:black;
    }
    transition:all .2s;
    body.offcanvasmenuvisible & {
        --hbnormal:white;
        --hbhover:white;
        transform: translateX(30%) scale(0.9);
    }
    
    @include breakpoint(large) {
        display: none;
    }
}

//override default styles
#offcanvasmenu {
    .verticallistmenu {
        ul {
            .level1 + .level1 {
                margin-top:1.25em;
            }
        }
    }
}

//typo
#offcanvasmenu {
    @include font-size-pt(34*0.75, 34);
    font-weight: 700;
    line-height: 1.2;

    .ctrlangchooser {
        font-weight: 500;
        font-size: 0.75em;
        .active {
            font-weight: 700;
        }
    }
}