.bl-accordion {
    .accitem {
        &:first-child {
            @include black-border-top;
        }
        @include black-border-bottom;

        .summary {
            .title {
                margin-bottom: 0;
            }
            .icon {
                @include rem-calc-sl(height, 40, 0.5);
                display: block;
                transition: transform 0.2s;
            }
        }
        &.accitem-compact .summary {
            display:flex;
            justify-content: space-between;
            align-items: center;

            margin-top:0.5em;
            margin-bottom:0.5em;
        }
        &.accitem-textwithimage {
            @include rem-calc-sl(--imagegap, 30, 1);
            .summary {
                display:flex;

                margin-top:var(--imagegap);
                margin-bottom:var(--imagegap);

                .ctrcols {
                    flex:1 1 auto;

                    margin-right: 0.5em;
                    
                    @include breakpoint(medium) {
                        @include grid;
                        grid-template-columns: repeat(2, 1fr);
                    }
                    @include breakpoint(small down) {
                        .ctrtext {
                            margin-top:1em;
                        }
                    }
                }
                .ctricon {
                    flex:0 0 auto;
                }

                .ctrimage {
                    img {
                        @include w100bl;
                    }
                }

                .ctrtext {
                    margin-top: 1em;
                    .morelink {
                        display: block;
                        margin-top: 1em;
                    }
                }
            }
        }

        .details {
            display:none;

            margin-bottom: 0.75em;
        }

        &.expanded {
            .icon {
                transform: rotate(45deg);
            }
            .morelink {
                opacity: 0;
            }
        }
    }
    .accitem.hascontent0 {
        .summary .morelink {
            display:none !important;
        }
        .summary .ctricon {
            visibility: hidden;
        }
    }
    .accitem.hascontent1 {
        .summary {
            cursor: pointer;
        }
    }
    .accitem.visible0 {
        display: none;
    }
}

//typo
.bl-accordion {
    .accitem-compact .summary {
        h2 {
            font:inherit;
            margin:0;
        }
    }
    .accitem-textwithimage .summary {
        .morelink {
            @include text-decoration-underline;
        }
    }
    .morelink-close {
        @include text-decoration-underline;
        cursor: pointer;
        display: block;
        margin-top: 2em;
        margin-bottom: 3em;
    }
}