.bl-partnerlist {
    .items {
        --itemsperrow: 2;
        @include breakpoint(medium) {
            --itemsperrow: 3;
        }
        @include breakpoint(large) {
            --itemsperrow: 4;
        }

        @include rem-calc-sl(--itemsgap, 100, 0.5);

        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: var(--itemsgap);

        & > * {
            min-width: 0;
            //width: calc(100% / 4 - 10px * (4-1) / 4);
            width: calc(100% / var(--itemsperrow) - var(--itemsgap) * (var(--itemsperrow) - 1) / var(--itemsperrow));
        }

        .ctrlogo {
            position: relative;
            //background-color: yellow;
            aspect-ratio: 2 / 1;
        }
        .logo {
            @include absposfull;
            /*
            border-style: solid;
            border-color:transparent;
            @include rem-calc-sl(border-width, 25, 0.5);
            */

            box-sizing: border-box;
            object-fit: contain;
            object-position: center center;
        }
    }
}

