.bl-imageblock {
    img {
        @include w100bl;
    }

    &.layoutleft {
        @include breakpoint(large) {
            width:75%;
        }
    }
}