footer {
  background-color: $black;
  color:$white;

  @include rem-calc-sl(padding-top, 48, 0.5);
  @include rem-calc-sl(padding-bottom, 48, 0.5);

  @include breakpoint(large) {
    .ctrcols {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @include breakpoint(medium down) {
    .col2, .col3 {
      margin-top: 2em;
    }
  }

  .ctrsocial {
    margin-top:2em;
    
    display:flex;
    gap:1.25em;

    .icon {
      @include rem-calc-sl(height, 32, 1);
    }
  }
}

//typo
footer {
  @include typo-footer;
}