@font-face {
    font-family: "HelveticaNow";
    src: url("../fonts/3A9C6A_0_0.eot");
    src: url("../fonts/3A9C6A_0_0.eot?#iefix") format("embedded-opentype"),
      url("../fonts/3A9C6A_0_0.woff2") format("woff2"),
      url("../fonts/3A9C6A_0_0.woff") format("woff"),
      url("../fonts/3A9C6A_0_0.ttf") format("truetype");
    font-weight: 400;
  }
  
  @font-face {
    font-family: "HelveticaNow";
    src: url("../fonts/3A9C6A_1_0.eot");
    src: url("../fonts/3A9C6A_1_0.eot?#iefix") format("embedded-opentype"),
      url("../fonts/3A9C6A_1_0.woff2") format("woff2"),
      url("../fonts/3A9C6A_1_0.woff") format("woff"),
      url("../fonts/3A9C6A_1_0.ttf") format("truetype");
    font-weight: 700;
  }
  