.bl-referenzspecs {
    @include breakpoint(large) {
        @include grid12;
        .linner {
            grid-column: 3 / span 8;
        }
    }
    .items {
        display:flex;
        @include rem-calc-sl(gap, 80, 0.3);
        @include breakpoint(small down) {
            flex-direction: column;
        }
    }
}

//typo
.bl-referenzspecs {
    @include font-size-pt(17, 17);
    font-weight: 400;
    line-height: math.div(24, 17);

    h3 {
        font-weight: 700;
    }
}