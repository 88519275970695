.bl-iconlist {
    .items {
        @include grid;
        grid-template-columns: repeat(2, 1fr);
        @include breakpoint(large) {
            grid-template-columns: repeat(4, 1fr);
        }

        .iconimg {
            @include w100bl;
        }
    }

    .iconimg {
        max-width:rem-calc(240);
        margin-left: auto;
        margin-right: auto;
    }
    .ctrtext {
        max-width:rem-calc(235);
        margin-left: auto;
        margin-right: auto;

        @include rem-calc-sl(margin-top, 60, 0.5);
    }
}

//typo
.bl-iconlist {
    h1 {
        text-align: center;
    }
    .ctrtext {
        @include font-size-pt(14, 17);
        line-height: math.div(24, 17);

        h3 {
            font:inherit;
            font-weight:700;
        }

        text-align: center;
    }
}