.bl-2img {
    .ctrcols {
        @include grid;
        grid-template-columns: repeat(2, 1fr);
        gap:var(--imagegap);
        @include breakpoint(medium down) {
            grid-template-columns: 1fr;
        }
    }
    .bigimg {
        @include w100bl;

        /*
        height:100%; //Unterschiedliche Bildhoehen ausgleichen
        object-fit: cover;
        object-position: center center;
        */
    }
}

//typo
.bl-2img {
}