#menubutton {
    .icon {
        @include w100bl;
        transition: color 0.1s;

        body.desktopmenuvisible & {
            color:$white;
        }
    }
    @include rem-calc-sl(width, 46, 0.75);
    /*
    position: fixed;
    @include rem-calc-sl(right, 45, 0.5);
    @include rem-calc-sl(top, 41, 0.5);
    */
    z-index: 100;
    cursor: pointer;
}
