.hamburger {
  --hbnormal:#000;
  --hbhover:#000;

  //position: fixed;
  //right: 0;
  width: rem-calc(50);
  //margin: rem-calc(20);
  //z-index: 99;
  button {
    position: relative;
    width: rem-calc(50);
    height: rem-calc(24);
    display: block;
    cursor: pointer;
    border-style: none;
    padding:0;
    background:none;
    appearance: none;
  }

  span {
    display: block;
    background-color: var(--hbnormal);
    position: absolute;
    width: 100%;
    height: 3px;
    top: rem-calc(0); /*0*/
    //border-radius: 1px;
    opacity: 1;
    transition: all 0.25s ease-in-out;
    + span {
      top: rem-calc(11);
      + span {
        top: rem-calc(22); /*22*/
        display:block;
      }
    }
  }
  &:hover span {
    background-color: var(--hbhover);
  }
  &.is-active {
    span {
      transform: rotate(45deg);
      top: rem-calc(10);
      + span {
        opacity: 0;
        + span {
          opacity: 1;
          transform: rotate(-45deg);
        }
      }
    }
    &:hover {
      span {
        transform: rotate(135deg);
        + span + span {
          transform: rotate(-135deg);
        }
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
  &:not(.is-active):hover {
    span {
      transform: translateY(-2px);
      + span {
        transform: translateY(0px);
        + span {
          transform: translateY(2px);
        }
      }
    }
  }
}
