.bl-herohome {
    position: relative;

    height:100vh;
    height:100lvh;
    &.layouthalfh {
        height:50vh;
        height:50lvh;
        min-height: rem-calc(470);
    }

    .ctrimage {
        @include absposfull;
        img {
            @include absposfull;
            object-fit: cover;
            object-position: center center;
        }
    }
    
    .ctrtext {
        position: absolute;
        left:0;
        right:0;
        bottom:15vh;

        .text {
            text-align: center;
        }
        .buttons {
            margin-top:10vh;

            display:flex;
            justify-content: center;
            gap:1.5em;
            @include breakpoint(small down) {
                flex-direction: column;
                align-items: center;
            }
        }
    }
}

//typo
.bl-herohome {
    .text {
        @include font-size-pt(60*0.5, 60);
        font-weight: 700;
        line-height:math.div(82, 60); 
        //max-width: 14em; //ContentBlock Einstellung erstellt
        margin-left: auto;
        margin-right: auto;
    }
}