.bl-referenzlist {
    .guttersizer {
        visibility: hidden;
        width:var(--layoutgridgap);
    }
    .item {
        width:calc((100% - 2*var(--layoutgridgap)) / 3);
        @include breakpoint(medium down) {
            width:calc((100% - 1*var(--layoutgridgap)) / 2);
        }
        @include breakpoint(small down) {
            width:100%;
        }

        margin-bottom: var(--layoutgridgap); //"To set vertical space between elements, use margin CSS." see https://isotope.metafizzy.co/layout-modes/masonry

        .ctrimg img {
            @include w100bl;
        }

        h2 {
            margin:0.5em 0 0.5em 0;
        }
    }

    .ctrfilterbuttons {
        display:flex;
        @include rem-calc-sl(column-gap, 60, 0.5);
        @include rem-calc-sl(row-gap, 30, 0.5);
        flex-wrap: wrap;
        justify-content: center;

        .filterbutton {
            background-color: $kkbton03;
            padding:0.5em 1em;

            cursor: pointer;

            transition:all .2s;
            &:hover, &.active {
                background-color: $kkhauptrot;
                color:#fff;
            }
        }

        @include breakpoint(small down) {
            justify-content: flex-start;
        }
    }

    .items {
        @include rem-calc-sl(margin-top, 80, 0.5);
    }
}

//typo
.bl-referenzlist {
    .filterbutton {
        @include font-size-pt(17, 17);
        font-weight: 400;
        line-height: math.div(24, 17);

        color:$kkbton01;
    }
}
