.bl-referenzlistteaser {
    @include breakpoint(large) {
        .items {
            @include grid;
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @include breakpoint(medium down) {
        .item + .item {
            margin-top:var(--layoutgridgap);
        }
    }

    h1 {
        margin-bottom: 1.5em;
    }

    .item {
        .ctrimg img {
            @include w100bl;
        }

        .ctrinfo h2 {
            margin:0.5em 0 0 0;
        }
    }

    .ctrbuttons {
        @include rem-calc-sl(margin-top, 80, 0.5);
        display:flex;
        justify-content: center;
    }
}
