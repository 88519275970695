.bl-imagelinks {
    @include breakpoint(medium) {
        .items {
            @include grid;
            grid-template-columns: repeat(2, 1fr);
            gap:0;
        }
    }
    .item {
        position: relative;
        display:block;
        aspect-ratio: 1 / 1.5;
        img {
            @include absposfull;
            object-fit: cover;
            object-position: center center;
        }
        
        .ctroverlay {
            @include absposfull;
            //background-color:rgba(0, 0, 0, 0.3);

            display: flex;
            justify-content: center;
            align-items: center;

            h2 {
                margin-bottom: 0;
            }
        }

        overflow: hidden;
        img {
            transition: transform .4s;
        }
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }
}

//typo
.bl-imagelinks {
    h2 {
        @include font-size-pt(80*0.6, 80);
        font-weight: 700;
        color:$white;
        margin:0;
    }
}