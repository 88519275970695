.bl-joblist {
    @include breakpoint(large) {
        @include grid12;

        & > .linner {
            grid-column: 3 / span 8;
        }
    }

    .items {
        .item + .item {
            margin-top: var(--layoutgridgap);
        }

        .item {
            @include rem-calc-sl(padding-top, 30, 0.5);
            @include rem-calc-sl(padding-bottom, 30, 0.5);
            @include rem-calc-sl(padding-left, 40, 0.5);
            @include rem-calc-sl(padding-right, 40, 0.5);
            
            background-color: white;
            display:flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

//typo
.bl-joblist {
    h2 {
        margin-bottom: 0;
    }
    .text {
        margin-top:0.4em;

        @include font-size-pt(17, 17);
        font-weight: 400;
        line-height: math.div(24, 17);
    }
}

//hover effect
.bl-joblist {
    .item, h2 {
        transition: all .2s;
    }
    .item:hover {
        transform:translateY(-5%);
        background-color: $kkrot;
        h2 {
            color:white;
        }
    }
}