.icon-sprites {
  display: none;
}

svg.icon {
  aspect-ratio: 1;
  height: 1em;
  //fill:currentColor;
}

svg.icon-tripadvisor {
  aspect-ratio: 37 / 22;
}