.bl-zimmerausstattung {
    h1 {
        margin-bottom:1.5em;
    }
    @include breakpoint(medium) {
        .ctrmaincols {
            @include grid;
            grid-template-columns: rem-calc(310) auto;
            gap:0;
        }
    }
    .ctrcoupon, .ctrvorteile {
        @include rem-calc-sl(padding-left, 50, 0.5);
        @include rem-calc-sl(padding-right, 50, 0.5);
        @include rem-calc-sl(padding-top, 70, 0.5);
        @include rem-calc-sl(padding-bottom, 80, 0.5);
    }
    .ctrcoupon {
        background-color: $green;
        background-image: url('/assets/app/images/ausstattung/bg-ihre-vorteile.jpg');
        background-size: cover;
        background-position: 70% 30%;
        @include breakpoint(small down) {
            background-position: 100% 15%;        
        }
            
        display:flex;
        flex-direction: column;
        justify-content: flex-end;

        .imgfooddrinks {
            width:rem-calc(114);
            display: block;
            margin-bottom: 1em;
        }
        .ctrlinks {
            a {
                display: block;
            }
        }
    }
    .ctrvorteile {
        background-color: $brown;
    }
    @include breakpoint(large) {
        .ctrfeatures {
            @include grid;
            gap:2em;
            
            grid-template-columns: repeat(2, auto);
            @include breakpoint(xlarge) {
                grid-template-columns: repeat(3, auto);
            }
            justify-content: space-between;    
            
            /*
            grid-auto-flow:column;
            grid-template-rows: repeat(3, auto);
            */
        }
    }
    .feature {
        display:flex;
        align-items: center;
        gap:0.75em;
        img {
            display: block;
            height:1.2em;
        }
    }
    @include breakpoint(medium down) {
        .feature + .feature {
            margin-top:0.5em;
        }
    }
}

//typo
.bl-zimmerausstattung {
    .ctrcoupon {
        @include font-size-pt(18, 18);
        font-weight:700;
        .ctrlinks {
            @include font-size-pt(16, 16);
            font-weight:500;
            @include text-decoration-underline;
        }
    }
}