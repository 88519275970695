header {
  .ctrcols {
    @include rem-calc-sl(padding-top, 30, 0.5);
    @include rem-calc-sl(padding-bottom, 30, 0.5);

    display:flex;
    justify-content: space-between;
    align-items: center;
  }
  .ctrlogo {
    @include rem-calc-sl(width, 200, 0.75);
    img {
      @include w100bl;
    }
  }
}

header {
  //menu
  .ctrmenu {
    ul {
      gap:2em;
    }
  }
}

//responsive
header {
  @include breakpoint(medium down) {
    .ctrmenu {
      display: none;
    }
  }
}

//absolute positioning
header {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  z-index: 10;
}

//white variant
body.headercolorwhite header:not(.opaque) {
  color:$white;
  .ctrlogo {
    filter:invert(1);
  }
}

//"opaque" scroll down effect
header {
  transition:background-color .2s;
  .ctrlogo {
    transition:width .2s;
  }
}
header.opaque {
  background-color: rgba($white, 0.6);
  @include breakpoint(small down) {
    background-color: $white;
  }
  .ctrlogo {
    @include rem-calc-sl(width, 150, 0.75);
  }
}

//typo
header {
  .ctrmenu {
    @include typo-menu;
    .active a {
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-underline-offset: 0.5em;
    }
  }
}