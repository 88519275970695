.bl-teamlist {
    @include breakpoint(medium) {
        .item {
            @include grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
        }
    }
    .item {
        &:first-child {
            @include black-border-top;
        }
        @include black-border-bottom;
        @include rem-calc-sl(padding-top, 30, 1);
        @include rem-calc-sl(padding-bottom, 30, 1);
    }
    @include breakpoint(small down) {
        .ctrtext {
            margin-top:1em;
        }
    }
    .ctrimage {
        img {
            @include w100bl;
        }
    }
}

//typo
.bl-teamlist {
    .ctrtext {
        a {
            @include text-decoration-underline;
        }
        a[href^="tel:"] {
            text-decoration: none;
            &:hover {
                @include text-decoration-underline;
            }
        }
    }
}