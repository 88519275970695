@mixin grid {
    display:grid;
    gap:var(--layoutgridgap);
    & > * {
        min-width:0;
    }
}
@mixin grid12 {
    @include grid;
    grid-template-columns: repeat(12, 1fr);
}
@mixin gridrow1 {
    & > * {
        grid-row:1;
    }
}