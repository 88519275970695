$black:#000;
$white:#fff;

$textcolor:#000;
$footertext:#fff;

$brown: #64412c;
$green: #636E4B;
$graytext: #A3A3A3;
            $gray01: #BEBEBE;
            $gray02: #808080;

//TODO: entfernen. achtung: kompilierfehler bei dependencies.
                $kkhauptrot:#A61830;
                $kkrot:#E60037;
                $kkdunkelrot:#3A0B13;
                $kkbton01:#A89B92;
                $kkbton02:#E1CDBE; //Vorgehen scrollbar track
                $kkbton03: #F5EEE6;


/*
@mixin borderradius1 {
    //images
    border-radius: 2rem;
}
@mixin borderradius2 {
    //button
    border-radius: 0.5rem;
}
*/

@mixin black-border-top {
    border-top:2px solid $black;
}
@mixin black-border-bottom {
    border-bottom:2px solid $black;
}

$buttonbg:#000;
$buttonfg: #fff;
$buttonbg-hover:#333;
$buttonfg-hover: #fff;

:root {
    @include rem-calc-sl(--lrmargin, 50, 0.5);
    @include rem-calc-sl(--layoutgridgap, 60, 0.5);
    @include rem-calc-sl(--imagegap, 50, 0.5);
}


